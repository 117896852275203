var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.isLoading && (!_setup.fetchedFloors || !_setup.fetchedFloors.length))?_c('div',{staticClass:"loader-container"},[_c('div',{staticClass:"loader-title"},[_vm._v("Loading Tenants...")]),_c(_setup.Loader)],1):(_setup.fetchedFloors)?_c('div',{staticClass:"tenants-wrapper",style:(_setup.wrapperStyle)},[_c('div',{staticClass:"tenants-container"},_vm._l((_setup.pages),function(page,idx){return _c('div',{key:idx,staticClass:"tenants-page",class:{
        slideIn: idx === _setup.currentPage - 1,
        slideOut: idx !== _setup.currentPage - 1,
        'flex-display': _setup.containerStyle.display === 'flex',
      },style:(_setup.containerStyle)},_vm._l((page),function(floor,index){return _c('div',{key:index,staticClass:"floor"},[_c('div',{staticClass:"floor-title",style:(_setup.titleStyle)},[_vm._v(" "+_vm._s(floor.displayFloorName || `VÅNING ${floor.floor}`)+" ")]),_vm._l((floor.apartments),function(tenant,index){return _c('div',{key:index,staticClass:"tenants",class:_setup.apartmentsContainerStyle.flexDirection === 'row-reverse'
              ? 'style-number-first'
              : 'style-name-first',style:(_setup.apartmentsContainerStyle)},[_c('div',{staticClass:"tenant-name",style:(_setup.tenantNameStyle)},[_vm._v(" "+_vm._s(_setup.prepareName(tenant.names))+" ")]),_c('div',{staticClass:"tenant-number",style:(_setup.tenantNumberStyle)},[_vm._v(_vm._s(tenant.apartmentNumber))])])})],2)}),0)}),0),_c('div',{staticClass:"slides scrollbar",style:(_setup.paginationStyle)},[(!_vm.widgetData.tenants.paginationInText)?_vm._l((_setup.pages.length),function(index){return _c('div',{key:index,staticClass:"page-dot",on:{"click":function($event){return _setup.setCurrentPage(index)}}},[_c('div',{class:index === _setup.currentPage ? 'current-dot' : 'dot',style:({
            backgroundColor:
              index === _setup.currentPage ? `${_vm.widgetData.tenants.paginationFontColor}` : 'auto',
          })})])}):_c('div',{staticClass:"pagination-in-text"},[_vm._v(" "+_vm._s(`Sida ${_setup.currentPage}/${_setup.pages.length}`)+" ")])],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }