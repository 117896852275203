import * as Sentry from '@sentry/vue';

export function serializedError(error) {
  try {
    return JSON.stringify(error, Object.getOwnPropertyNames(error));
  } catch (error) {
    console.log('SerializedError ~ error:', error);
    return `Serialized Error failed: ${error ? error.message : ''}`;
  }
}

export class SentryLogger {
  static error(message, error = null) {
    if (error) {
      console.error(message, error);
    } else {
      console.error(message);
    }

    try {
      Sentry.captureException(
        new Error(`${message} ${error === null ? '' : `: ${serializedError(error)}`}`),
      );
    } catch (_error) {
      console.error('Failed to sent log to Sentry');
    }
  }

  static warn(message, error = null) {
    if (error) {
      console.warn(message, error);
    } else {
      console.warn(message);
    }

    try {
      Sentry.captureMessage(
        `${message} ${error === null ? '' : `: ${serializedError(error)}`}`,
        'warning',
      );
    } catch (_error) {
      console.error('Failed to sent log to Sentry');
    }
  }

  static info(message, error = null) {
    if (error) {
      console.info(message, error);
    } else {
      console.info(message);
    }

    try {
      Sentry.captureMessage(
        `${message} ${error === null ? '' : `: ${serializedError(error)}`}`,
        'info',
      );
    } catch (_error) {
      console.error('Failed to sent log to Sentry');
    }
  }

  static log(message, error = null) {
    if (error) {
      console.log(message, error);
    } else {
      console.log(message);
    }

    try {
      Sentry.captureMessage(
        `${message} ${error === null ? '' : `: ${serializedError(error)}`}`,
        'log',
      );
    } catch (_error) {
      console.error('Failed to sent log to Sentry');
    }
  }
}
