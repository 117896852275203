// @ts-nocheck
import axios from 'axios';
import localforage from 'localforage';
import { SESSION_TOKEN_ENUM } from '@/constants';
import { getDeviceSpecificParam } from '@/helpers/utils';
import { FileSynchronizationManager } from '@/utils/internalFileStorage/fileSynchronizationManager';
import axiosRetry from 'axios-retry';
import { NUMBER_OF_RETRIES, RETRY_WAIT_MIN_SECS, RETRY_WAIT_MAX_SECS } from '@/config/constants';

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem(SESSION_TOKEN_ENUM);
  config.headers.Authorization = token;

  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (401 === error?.response?.status) {
      localStorage.clear();
      localforage.clear();

      if (window.isWebOS === true) {
        await FileSynchronizationManager.removeAllInternalFiles();
      } else if (window.isAndroid === true) {
        // @ts-ignore
        window.logout();
      }

      window.location.href = `${window.location.origin}?${getDeviceSpecificParam()}`;
    }
    return Promise.reject(error);
  },
);

axiosRetry(axios, {
  retries: NUMBER_OF_RETRIES,
  retryDelay: () => {
    return (
      (Math.floor(Math.random() * (RETRY_WAIT_MAX_SECS - RETRY_WAIT_MIN_SECS + 1)) +
        RETRY_WAIT_MIN_SECS) *
      1000
    );
  },
  retryCondition: (error) => {
    return !!error.response?.status && error.response.status > 500;
  },
});

export default axios;
