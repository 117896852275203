import localforage from 'localforage';

import { SET_IS_ONLINE } from '@/store/actions/offline';

const state = {
  isAppOnline: navigator.onLine,
  offlineTimestamp: null,
};

const getters = {};

const actions = {};

const mutations = {
  [SET_IS_ONLINE]: (state, isOnline) => {
    state.isAppOnline = isOnline;

    let offlineTimestamp = localforage.getItem('offlineTimestamp');

    if (offlineTimestamp && isOnline) {
      offlineTimestamp = null;
      localforage.removeItem('offlineTimestamp');
    }

    if (!offlineTimestamp && !isOnline) {
      offlineTimestamp = Date.now();
      localforage.setItem('offlineTimestamp', offlineTimestamp);
    }

    state.offlineTimestamp = offlineTimestamp;
  },
};

export default {
  getters,
  state,
  actions,
  mutations,
};
