<script lang="ts">
  import { defineComponent } from 'vue';
  import { ALIGN_MODES } from '@/models/layoutDesigner';

  export default defineComponent({
    name: 'TenantsViewer',
  });
</script>

<script setup lang="ts">
  import { duration } from 'moment';
  import { ref, watch, toRef, computed, toRefs, onMounted, onUnmounted } from 'vue';
  import { apiGetDetailedAddress } from '@/api/tenants';
  import { Floor as ApiFloor } from '@/types/api/tenants';
  import Loader from '@/components/common/Loader.vue';
  import { Floor } from '@/types/widgets/tenantsWidget/tenantsWidget';
  import { divideArray } from '@/helpers';
  import { getRBGAColor } from '@/helpers/utils';

  const props = defineProps({
    widgetData: { type: Object, default: {} },
  });

  const timer = ref<ReturnType<typeof setInterval> | null>(null);
  const currentPage = ref<number>(1);
  const isLoading = ref(false);
  const fetchedFloors = ref<ApiFloor[]>([]);
  const refreshTimer = ref<ReturnType<typeof setInterval> | null>(null);

  const { tenants } = toRefs(props.widgetData);
  const slideInterval = toRef(props.widgetData.tenants, 'interval');

  watch(slideInterval, () => {
    resetSlide();
  });

  const containerStyle = computed(() => {
    return {
      display: tenants.value.verticalMode ? 'flex' : 'grid',
      flexDirection: 'column',
      gridAutoFlow: 'row',
      gridTemplate: 'repeat(16, auto) / 1fr 1fr',
    };
  });

  const apartmentsContainerStyle = computed(() => {
    const justifyContent = !tenants.value.groupNamesAndNumbers
      ? 'space-between'
      : tenants.value.textAlignment;

    return {
      flexDirection: !tenants.value.numbersOnTheLeft ? 'row' : 'row-reverse',
      justifyContent,
    };
  });

  const wrapperStyle = computed(() => {
    return {
      backgroundColor: getRBGAColor(
        tenants.value.backgroundColor,
        Number(tenants.value.opacity) / 100,
      ),
    };
  });

  const titleStyle = computed(() => {
    return {
      fontFamily: tenants.value.titleFont,
      fontSize: `${tenants.value.titleFontSize}px`,
      lineHeight: `${tenants.value.titleFontSize * 1.3}px`,
      color: tenants.value.titleFontColor,
      backgroundColor: tenants.value.titleBackgroundColor,
      textAlign: tenants.value.titleAlignment || ALIGN_MODES.LEFT_ALIGN,
    };
  });

  const tenantNameStyle = computed(() => {
    return {
      fontFamily: tenants.value.floorFont,
      fontSize: `${tenants.value.floorFontSize}px`,
      lineHeight: `${tenants.value.floorFontSize * 1.3}px`,
      color: tenants.value.floorFontColor,
      textAlign: tenants.value.textAlignment || ALIGN_MODES.LEFT_ALIGN,
      width: !tenants.value.groupNamesAndNumbers ? '100%' : 'auto',
    };
  });

  const tenantNumberStyle = computed(() => {
    return {
      fontFamily: tenants.value.roomsFont,
      fontSize: `${tenants.value.numberFontSize}px`,
      lineHeight: `${tenants.value.numberFontSize * 1.3}px`,
      color: tenants.value.numberFontColor,
      textAlign: tenants.value.numberAlignment || ALIGN_MODES.LEFT_ALIGN,
      display: !tenants.value.hideNumbers ? 'block' : 'none',
    };
  });

  const paginationStyle = computed(() => ({
    fontFamily: tenants.value.paginationFont,
    fontSize: `${tenants.value.paginationFontSize}px`,
    lineHeight: `${tenants.value.paginationFontSize * 1.2}px`,
    color: tenants.value.paginationFontColor,
    justifyContent: tenants.value.paginationAlignment || ALIGN_MODES.CENTER_ALIGN,
    height: tenants.value.paginationInText ? `${tenants.value.paginationFontSize + 16}px` : 'auto',
  }));

  const pages = computed<Floor[][]>(() => {
    if (!tenants.value.floors) return [];

    const filteredFloors = tenants.value.allFloorsVisible
      ? tenants.value.floors
      : tenants.value.floors.filter((floor: Floor) => floor && floor.visible !== false);

    return divideArray(filteredFloors, tenants.value.floorsOnSlide);
  });

  function setCurrentPage(value: number) {
    currentPage.value = value;
  }

  function nextTenantsSlide() {
    currentPage.value = currentPage.value < pages.value.length ? currentPage.value + 1 : 1;
  }

  function destroyTimer() {
    if (!timer.value) return;

    clearInterval(timer.value);
  }

  function resetSlide() {
    destroyTimer();
    setCurrentPage(1);

    timer.value = setInterval(nextTenantsSlide, slideInterval.value * 1000);
  }

  async function fetchTenants() {
    if (tenants.value.address.id === 'demo') return;

    try {
      const response = await apiGetDetailedAddress(tenants.value.address.id);
      const { floors } = response;

      tenants.value.floors = tenants.value.floors.map((floor: Floor) => {
        const currentFloor = floors.find(
          (currentFloor: ApiFloor) => currentFloor.floor === floor.floor,
        ) || {
          displayFloorName: `Våning ${floor.floor}`,
          floor: floor.floor,
          apartments: floor.apartments,
        };

        const displayFloorName =
          floor && floor.displayFloorName ? floor.displayFloorName : currentFloor.displayFloorName;

        return {
          ...floor,
          apartments: currentFloor.apartments || floor.apartments,
          visible: floor && floor.visible !== false,
          displayFloorName:
            displayFloorName === null && currentFloor.floor === 0
              ? 'Bottenvåning'
              : displayFloorName,
        };
      });

      fetchedFloors.value = floors;
    } catch (error: any) {
      console.log('Error fetching floors:', error.message);
    }

    isLoading.value = false;
  }

  const prepareName = (names: string[] = []) => {
    if (!tenants.value || !tenants.value.shortenNames) return names.join(', ');

    return names
      .map((name) => {
        const words = name.split(' ');

        if (words.length < 2) return name;

        const firstNameInitial = words[0][0] + '.';
        const lastName = words[words.length - 1];

        return `${firstNameInitial} ${lastName}`;
      })
      .join(', ');
  };

  onMounted(async () => {
    resetSlide();
    isLoading.value = true;
    await fetchTenants();
    isLoading.value = false;

    if (refreshTimer.value) {
      clearInterval(refreshTimer.value);
    }

    if (tenants.value.refreshTime && tenants.value.refreshTime > 0) {
      const refreshInterval = duration(
        tenants.value.refreshTime || 24,
        tenants.value.timeUnit || 'hours',
      ).asMilliseconds();

      refreshTimer.value = setInterval(fetchTenants, refreshInterval);
    }
  });

  onUnmounted(() => {
    destroyTimer();
    if (refreshTimer.value) clearInterval(refreshTimer.value);
  });
</script>

<template>
  <div v-if="isLoading && (!fetchedFloors || !fetchedFloors.length)" class="loader-container">
    <div class="loader-title">Loading Tenants...</div>
    <Loader />
  </div>

  <div v-else-if="fetchedFloors" class="tenants-wrapper" :style="wrapperStyle">
    <div class="tenants-container">
      <div
        v-for="(page, idx) in pages"
        :class="{
          slideIn: idx === currentPage - 1,
          slideOut: idx !== currentPage - 1,
          'flex-display': containerStyle.display === 'flex',
        }"
        :key="idx"
        class="tenants-page"
        :style="containerStyle"
      >
        <div v-for="(floor, index) in page" :key="index" class="floor">
          <div class="floor-title" :style="titleStyle">
            {{ floor.displayFloorName || `VÅNING ${floor.floor}` }}
          </div>

          <div
            v-for="(tenant, index) in floor.apartments"
            :key="index"
            class="tenants"
            :style="apartmentsContainerStyle"
            :class="
              apartmentsContainerStyle.flexDirection === 'row-reverse'
                ? 'style-number-first'
                : 'style-name-first'
            "
          >
            <div class="tenant-name" :style="tenantNameStyle">
              {{ prepareName(tenant.names) }}
            </div>
            <div class="tenant-number" :style="tenantNumberStyle">{{ tenant.apartmentNumber }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="slides scrollbar" :style="paginationStyle">
      <template v-if="!widgetData.tenants.paginationInText">
        <div
          v-for="index in pages.length"
          :key="index"
          class="page-dot"
          @click="setCurrentPage(index)"
        >
          <div
            :class="index === currentPage ? 'current-dot' : 'dot'"
            :style="{
              backgroundColor:
                index === currentPage ? `${widgetData.tenants.paginationFontColor}` : 'auto',
            }"
          ></div>
        </div>
      </template>
      <div v-else class="pagination-in-text">
        {{ `Sida ${currentPage}/${pages.length}` }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .tenants-wrapper {
    display: grid;
    grid-auto-flow: row;
    grid-template: 1fr auto / 1fr;
    width: 100%;
    height: 100%;
    padding-bottom: 10px;
  }

  .tenants-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }

  .tenants-page {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    gap: 24px;

    padding: 20px 20px 0;

    &.flex-display {
      // flex gap does not work in webOS so replacing it with margin;
      // grid gap seems to work
      gap: 0px;
      > *:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    .tenants {
      display: flex;
      align-items: center;
      // gap: 12px;
      &.style-number-first {
        .tenant-number {
          margin-right: 12px;
        }
      }
      &.style-name-first {
        .tenant-name {
          margin-right: 12px;
        }
      }
      padding: 8px 12px;
    }

    .floor-title {
      width: 100%;
      font-weight: bold;
      padding: 9px 11px;
      border-radius: 8px;
    }

    .tenant-number {
      font-weight: bold;
      width: 52px;
    }
  }

  .loader-container,
  .error-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 16px;
    // gap: 16px;
    > *:not(:last-child) {
      margin-bottom: 16px;
    }

    .loader-title {
      font-size: 16px;
      text-align: center;
    }

    .loading-placeholder {
      height: 40px;
    }
  }

  .error-message {
    font-size: 16px;
    text-align: center;
  }

  .slideIn {
    animation: slide-in 1s forwards;
    -webkit-animation: slide-in 1s forwards;
  }

  .slideOut {
    animation: slide-out 1s forwards;
    -webkit-animation: slide-out 1s forwards;
  }

  @keyframes slide-in {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @-webkit-keyframes slide-in {
    100% {
      -webkit-transform: translateX(0%);
    }
  }

  @keyframes slide-out {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  @-webkit-keyframes slide-out {
    0% {
      -webkit-transform: translateX(0%);
    }
    100% {
      -webkit-transform: translateX(-100%);
    }
  }

  .slides {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 20px;
    width: 100%;
    flex-wrap: wrap;

    .page-dot {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 34px;
      height: 20px;
      border-radius: 100%;
      padding: 3px 10px;

      &:hover {
        cursor: pointer;

        .dot {
          background-color: #151515;
          opacity: 40%;
        }
      }
    }

    .dot,
    .current-dot {
      background-color: #151515;
      border-radius: 100%;
    }

    .dot {
      opacity: 20%;
      width: 8px;
      height: 8px;
    }

    .current-dot {
      background-color: #151515;
      width: 14px;
      height: 14px;
    }
  }
</style>
