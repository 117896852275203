import Vue, { getCurrentInstance } from 'vue';
import Vuex from 'vuex';
import rss from '@/store/modules/rss';
import player from '@/store/modules/player';
import playlist from '@/store/modules/playlist';
import offline from '@/store/modules/offline';

Vue.use(Vuex);

// const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    rss,
    player,
    playlist,
    offline,
  },
  // strict: debug,
  strict: false, // this is necessary to avoid an error when editing forms
  // that contain vuex state data.
});

export const useStore = () => {
  const vm = getCurrentInstance();
  if (!vm) throw new Error('must be called in setup');
  return vm.proxy.$store;
};
