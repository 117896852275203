export const PLAYER_LOAD_LAYOUT = 'PLAYER_LOAD_LAYOUT';
export const PLAYER_LOAD_LAYOUT_ERROR = 'PLAYER_LOAD_LAYOUT_FAILURE';

export const PLAYER_LOAD_PLAYLISTS = 'PLAYER_LOAD_PLAYLISTS';
export const PLAYER_LOAD_PLAYLISTS_SUCCESS = 'PLAYER_LOAD_PLAYLISTS_SUCCESS';
export const PLAYER_LOAD_PLAYLISTS_ERROR = 'PLAYER_LOAD_PLAYLISTS_FAILURE';

export const PLAYER_LOAD_LAYOUT_CHILDREN = 'PLAYER_LOAD_LAYOUT_CHILDREN';
export const PLAYER_SET_LAYOUT = 'PLAYER_SET_LAYOUT';
export const SET_LAYOUT_CHILDREN = 'SET_LAYOUT_CHILDREN';
export const SET_PLAYLIST_CHILDREN = 'SET_PLAYLIST_CHILDREN';
export const SET_TEMPLATE_ITEMS = 'SET_TEMPLATE_ITEMS';
export const SET_PLAYLIST_TEMPLATE_ITEMS = 'SET_PLAYLIST_TEMPLATE_ITEMS';
export const SET_LOCAL_TEMPLATE_ITEMS = 'SET_LOCAL_TEMPLATE_ITEMS';
export const SET_APP_DATA = 'SET_APP_DATA';

export const USE_PLAYER_CACHED_DATA = 'USE_PLAYER_CACHED_DATA';
export const SET_PLAYER_ROTATION = 'SET_PLAYER_ROTATION';

export const RESET_PLAYER = 'RESET_PLAYER';

export const PLAYER_SET_LAYOUT_ORIENTATION = 'PLAYER_SET_LAYOUT_ORIENTATION';

export const OPEN_PLAYER_MODAL = 'OPEN_PLAYER_MODAL';
export const CLOSE_PLAYER_MODAL = 'CLOSE_PLAYER_MODAL';

export const PLAYER_CLEAR_RETRY_INTERVAL = 'PLAYER_CLEAR_RETRY_INTERVAL';
export const PLAYER_SETUP_HEALTH_CHECK = 'PLAYER_SETUP_HEALTH_CHECK';
export const PLAYER_HANDLE_LOAD_ERROR = 'PLAYER_HANDLE_LOAD_ERROR';
