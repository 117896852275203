<template>
  <div :id="widget.object.item_id" class="widget-item" :style="style">
    <div v-if="widget.object.useLink" :style="{ height: '100%', width: '100%' }">
      <iframe
        v-if="widget.object.link && widget.object.link.length !== 0 && showLayer"
        class="iframe-container"
        allow="accelerometer"
        :src="iframeSource"
        :title="widget.object.link"
        scrolling="no"
      />
    </div>
  </div>
</template>

<script>
  import { duration } from 'moment';

  import playerMixins from '@/models/player';
  import { simpleTypeMixin } from '@/helpers';
  import { getRBGAColor } from '@/helpers/utils';

  export default {
    name: 'LayerWidget',

    mixins: [simpleTypeMixin, playerMixins],

    props: {
      widget: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        showLayer: true,
        refreshTimer: null,
        refreshCount: 0,
      };
    },

    mounted() {
      if (this.refreshTimer) {
        clearInterval(this.refreshTimer);
      }

      if (
        this.widget.object.useLink &&
        !!this.widget.object.link &&
        this.widget.object.refreshTime &&
        this.widget.object.refreshTime > 0
      ) {
        const refreshInterval = duration(
          this.widget.object.refreshTime || 24,
          this.widget.object.timeUnit || 'hours',
        ).asMilliseconds();

        this.refreshTimer = setInterval(this.refreshLayer, refreshInterval);
      }
    },

    beforeDestroy() {
      if (this.refreshTimer) clearInterval(this.refreshTimer);
    },

    computed: {
      style() {
        const style = this.defaultStyling(this.widget);

        return {
          ...style,
          overflow: 'hidden',
          backgroundColor: getRBGAColor(
            this.widget.object.backgroundColor,
            this.widget.object.opacity / 100,
          ),
        };
      },

      iframeSource() {
        // Add refresh count as a query parameter to force iframe refresh
        const baseUrl = this.widget.object.link;
        const hasQueryParams = baseUrl.includes('?');
        const separator = hasQueryParams ? '&' : '?';

        return `${baseUrl}${separator}_refresh=${this.refreshCount}`;
      },
    },

    methods: {
      refreshLayer() {
        this.showLayer = false;
        this.refreshCount++;
        const _this = this;

        setTimeout(function () {
          _this.showLayer = true;
        }, 100);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .widget-item {
    position: absolute;
    overflow: hidden;
  }

  .iframe-container {
    width: 100%;
    height: 100%;
    border: 0;
    pointer-events: none;
    overflow: hidden;
  }

  .layer-link {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: #6a6b6a;
    font-size: 32px;

    i {
      font-size: 64px;
    }
  }
</style>
