<template>
  <div class="rss">
    <OfflineMessage v-if="showOfflineMessage" message="No RSS feed data" />

    <template v-else>
      <img
        v-if="rssRequestStatus === 'loading'"
        src="@/assets/img/rss/preloader.svg"
        class="rss-loading"
      />

      <div v-else-if="rssRequestStatus === 'error' && rssRequestError" class="rss-error p-3">
        <h5>{{ rssRequestError }}</h5>
      </div>

      <template v-else-if="rssFeed">
        <transition-group
          name="fade"
          mode="in-out"
          :duration="500"
          tag="div"
          style="width: 100%; height: 100%; overflow: hidden"
        >
          <template v-for="(item, index) in rssFeed.items">
            <div
              v-if="index === currentIndex"
              class="rss-slides"
              :class="[setting.rssTheme]"
              :key="index"
            >
              <div class="slide-container">
                <div
                  v-if="setting.display.image"
                  class="item-image"
                  :style="{ width: setting.display.imageWidth + '%' }"
                >
                  <img
                    :src="getItemImage(item)"
                    :alt="item.title"
                    :style="{ objectFit: setting.display.imageMode || 'cover' }"
                  />
                </div>

                <div
                  class="item-body"
                  :style="{
                    width: (setting.display.image ? 100 - setting.display.imageWidth : 100) + '%',
                    padding: setting.display.padding + 'px',
                  }"
                >
                  <div
                    v-if="setting.display.rssName"
                    class="item-header"
                    :style="{
                      'margin-bottom': setting.display.spacing + 'px',
                    }"
                  >
                    <div
                      class="header-title"
                      :style="{
                        fontSize: setting.display.feedNameSize + 'px',
                        lineHeight: setting.display.feedNameSize * 1.2 + 'px',
                        color: setting.rssTheme === 'black' ? '#ffbf5f' : 'inherit',
                      }"
                    >
                      <div class="rss-owner">
                        <div class="rss-icon-container">
                          <img src="@/assets/img/rss/rss-icon.png" class="rss-icon" />
                        </div>

                        <div>
                          {{ rssOwner }}
                        </div>
                      </div>

                      <div
                        v-if="item.pubDate"
                        class="item-date"
                        :style="{
                          fontSize: setting.display.feedNameSize + 'px',
                          lineHeight: setting.display.feedNameSize * 1.2 + 'px',
                          color: setting.rssTheme === 'black' ? '#ffbf5f' : 'inherit',
                        }"
                      >
                        <i class="material-icons-outlined align-middle">today</i>
                        {{ relativeDay(item.pubDate) }}
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="item.title"
                    class="item-title scroolbar"
                    :style="{
                      fontSize: setting.display.titleFontSize + 'px',
                      lineHeight: setting.display.titleFontSize * 1.2 + 'px',
                      'margin-bottom': setting.display.spacing + 'px',
                    }"
                  >
                    {{ item.title }}
                  </div>

                  <div class="description-container scrollbar">
                    <div
                      v-if="setting.display.content"
                      class="item-description"
                      :style="{
                        fontSize: setting.display.contentSize + 'px',
                        lineHeight: setting.display.contentSize * 1.2 + 'px',
                      }"
                    >
                      {{ rssContent }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </transition-group>
      </template>

      <div v-else class="no-rss">
        <img :src="rssInvalidImage" class="rss-invalid" />
      </div>
    </template>

    <FloatingOfflineIndicator v-if="!isAppOnline" />
  </div>
</template>

<script>
  // eslint-disable-next-line
  import moment from 'moment';
  import localforage from 'localforage';

  import FloatingOfflineIndicator from '@/components/common/FloatingOfflineIndicator.vue';
  import OfflineMessage from '@/components/common/OfflineMessage.vue';
  import rssInvalidImage from '@/assets/img/rss/rss_invalid.svg';

  import { apiGetRss } from '@/api/rss';
  import { cacheData } from '@/helpers/caching';

  export default {
    name: 'RssViewer',

    emits: ['saveData'],

    components: {
      FloatingOfflineIndicator,
      OfflineMessage,
    },

    props: {
      setting: {
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return {
        CAROUSEL_TRANSITION_INTERVAL: 10 * 1000, // 10 secs,
        rssRequestStatus: null,
        rssRequestError: null,
        rendering: true,
        showOfflineMessage: false,
        offlineCheckTimer: null,

        rssUpdateEvery: 30, //mins
        rssUpdateInterval: null,
        currentIndex: 0,
        timer: null,
        rssInvalidImage,
      };
    },

    mounted() {
      this.initRssViewer();

      if (!this.isAppOnline && !this.offlineCheckTimer) {
        this.startOfflineCheck();
      }

      if (!this.rssUpdateInterval) {
        this.rssUpdateInterval = setInterval(this.initRssViewer, 10 * 1000);
      }
    },

    beforeDestroy() {
      this.clearOfflineCheckTimer();
      this.clearRssUpdateInterval();
      this.clearSlideTimer();
    },

    watch: {
      isAppOnline(isOnline) {
        if (!isOnline) {
          this.startOfflineCheck();
          return;
        }

        if (this.offlineCheckTimer) {
          this.initRssViewer();
          this.clearOfflineCheckTimer();
        }
      },
    },

    computed: {
      theme() {
        if (this.setting.rssTheme === 'empty') {
          return '';
        }

        return this.setting.rssTheme === 'white' ? 'light-theme' : 'dark-theme';
      },

      duration() {
        if (this.setting.rssDuration > 0) {
          return this.setting.rssDuration * 1000;
        } else {
          return this.CAROUSEL_TRANSITION_INTERVAL;
        }
      },

      rssOwner() {
        return this.rssFeed && this.rssFeed.title ? this.rssFeed.title.split('|')[0] : 'News';
      },

      rssContent() {
        let currentDescription =
          this.rssFeed && this.rssFeed.items[this.currentIndex]
            ? this.rssFeed.items[this.currentIndex].description || ''
            : '';

        const isToolong =
          this.setting.display.contentLength > 0 &&
          currentDescription.length > this.setting.display.contentLength;

        if (isToolong) {
          currentDescription = currentDescription.slice(0, this.setting.display.contentLength);
        }

        return isToolong ? currentDescription + '...' : currentDescription;
      },

      rssFeed() {
        return this.$store.state.player.appsData[this.setting.appId]
          ? this.$store.state.player.appsData[this.setting.appId].data
          : null;
      },

      isAppOnline() {
        return this.$store.state.offline.isAppOnline;
      },

      offlineTimestamp() {
        return this.$store.state.offline.offlineTimestamp;
      },
    },

    methods: {
      startSlide() {
        this.currentIndex = 0;

        if (this.timer) clearInterval(this.timer);

        this.timer = setInterval(this.nextPage, this.duration);
      },

      nextPage() {
        if (!this.rssFeed) return;

        this.currentIndex =
          this.currentIndex < this.rssFeed.items.length - 1 ? this.currentIndex + 1 : 0;
      },

      async initRssViewer() {
        this.rssRequestError = null;

        try {
          if (this.isAppOnline) {
            if (!this.rssFeed || this.checkIfRefreshNeeded()) {
              await this.loadRSSFeed();
            }

            return;
          }

          if (this.rssFeed) return;

          const data = await localforage.getItem(`rss-app-${this.setting.appId}`);

          if (!data) {
            this.showOfflineMessage = true;
            return;
          }

          this.$emit('saveData', data);
          this.showOfflineMessage = false;

          this.startSlide();
        } catch (error) {
          console.log('error: ', error);
          this.rssRequestError = 'Failed to load RSS feed';
        }
      },

      checkIfRefreshNeeded() {
        const aFewMinutesAgo = Date.now() - this.rssUpdateEvery * 60 * 1000;

        return this.rssFeed && this.rssFeed.lastFetchTimestamp <= aFewMinutesAgo;
      },

      startOfflineCheck() {
        if (this.offlineCheckTimer) return;

        this.offlineCheckTimer = setInterval(this.initRssViewer, 2 * 60 * 1000);
      },

      clearOfflineCheckTimer() {
        if (!this.offlineCheckTimer) return;

        clearInterval(this.offlineCheckTimer);
        this.offlineCheckTimer = null;
      },

      clearRssUpdateInterval() {
        if (!this.rssUpdateInterval) return;

        clearInterval(this.rssUpdateInterval);
        this.rssUpdateInterval = null;
      },

      clearSlideTimer() {
        if (!this.timer) return;

        clearInterval(this.timer);
        this.timer = null;
      },

      async loadRSSFeed() {
        this.rssRequestStatus = 'loading';
        this.rssRequestError = null;

        try {
          const response = await apiGetRss(this.setting.rssLink);

          const { data } = response;
          const rssLinkShouldHaveFeedData = !data.title && data.items.length === 0;

          if (!response || rssLinkShouldHaveFeedData) {
            const cachedData = await localforage.getItem(`rss-app-${this.setting.appId}`);

            if (!cachedData) {
              throw new Error('Failed to fetch RSS feed and no cached data available');
            }
            return cachedData;
          }

          const rssData = { ...data, lastFetchTimestamp: Date.now() };
          cacheData(`rss-app-${this.setting.appId}`, rssData);

          this.$emit('saveData', rssData);

          this.showOfflineMessage = false;
          this.rssRequestStatus = 'success';
          this.startSlide();
        } catch (error) {
          console.error('Failed to get RSS feed:', error);
          this.rssRequestStatus = 'error';
          this.rssRequestError = "'" + this.setting.rssLink + "'" + 'is not a valid RSS URL';
        }
      },

      relativeDay(value) {
        return moment(value).fromNow();
      },

      getItemImage(item) {
        if (item.teaserImage) {
          return item.teaserImage;
        }

        if (this.rssFeed.image) {
          return this.rssFeed.image;
        }

        return rssInvalidImage;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .rss {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    .rss-slides {
      position: absolute;
      width: 100%;
      height: 100%;

      &.white {
        background-color: white;
        color: black;
      }

      &.black {
        background-color: black;
        color: white;
      }

      .slide-container {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;

        display: flex;
      }

      .item-image {
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .item-body {
        display: flex;
        flex-direction: column;
        padding: 5% 16px;
        text-overflow: ellipsis;

        .item-header {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .header-title {
            display: flex;
            width: 100%;
            justify-content: space-between;
            flex-wrap: wrap;
            font-size: 16px;
            font-weight: 500;
            color: #6a6b6a;
            padding-right: 8px;
          }

          .rss-owner {
            display: flex;
            align-items: center;
          }

          .rss-icon-container {
            width: 20%;
            max-width: 60px;
            min-width: 30px;
            padding: 8px;
            margin-right: 6px;
          }

          .rss-icon {
            width: 100%;
            border-radius: 20%;
            height: auto;
          }
        }

        .item-title {
          font-size: 24px;
          font-weight: 500;
          line-height: 30px;
        }

        .item-date {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 500;
          color: #6a6b6a;

          i {
            color: #6a6b6a;
            margin-right: 4px;
          }
        }

        .item-description {
          width: 100%;
          height: 100%;
        }

        .description-container {
          display: flex;
          flex-grow: 1;
          overflow-x: hidden;
          overflow-y: auto;

          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
          -ms-text-overflow: ellipsis;
          overflow-wrap: break-word;
        }
      }
    }

    .rss-invalid {
      width: 100%;
    }

    .rss-loading {
      width: 30%;
      height: 30%;
      max-width: 50px;
      max-height: 50px;
    }

    .rss-error {
      text-align: center;
      width: 100%;
      height: 100%;
    }
  }
</style>
