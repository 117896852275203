<script>
  export default {
    name: 'EmptyState',
    props: {
      title: {
        type: String,
        default: 'Nothing to show',
      },
      content: {
        type: String,
        default: '',
      },
    },
  };
</script>

<template>
  <div class="container">
    <svg
      class="icon"
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="52"
      viewBox="0 0 60 52"
      fill="none"
    >
      <path
        d="M30.0004 24.5586L30.0004 10.5845L9.16943 17.6678"
        stroke="#E3003A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.16943 29.9619L9.16943 43.9166L30.0004 50.9998V24.75"
        stroke="#E3003A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M50.8306 30.2667V43.9166L29.9996 50.9998V24.75"
        stroke="#E3003A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M50.831 17.666L30 24.7493L38.169 34.3326L59 27.2493L50.831 17.666Z"
        stroke="#E3003A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.16901 17.666L30 24.7493L21.831 34.3326L1 27.2493L9.16901 17.666Z"
        stroke="#E3003A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.16901 17.666L30 10.5827L21.831 0.999447L1 8.08274L9.16901 17.666Z"
        stroke="#E3003A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M50.831 17.6758L30 10.5925L38.169 1.00921L59 8.0925L50.831 17.6758Z"
        stroke="#E3003A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <div class="text-h6">{{ title }}</div>
    <div class="text-body2">{{ content }}</div>
  </div>
</template>

<style scoped lang="scss">
  .text-h6 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 2rem;

    font-family: 'Poppins', serif;
    color: #151515;
  }

  .text-body2 {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;

    font-family: 'Poppins', serif;
    color: #333332;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 12px;
    width: 100%;
    height: 100%;
    background: white;
  }

  .icon {
    width: 50px;
    height: 44px;
    margin-bottom: 12px;
  }
</style>
