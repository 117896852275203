<template>
  <div ref="weatherContainer" class="weather-container" :style="{ backgroundColor: background }">
    <OfflineMessage v-if="showOfflineMessage" message="No weather data" />

    <div v-else-if="loading" class="loading">
      <img src="@/assets/img/weather/loader.svg" alt="loading" />
    </div>

    <div v-else-if="error" class="weather-error">Unable to fetch weather data</div>

    <div v-else-if="weatherData" :style="containerStyle">
      <div
        v-if="!app.weather || app.weather.type === WEATHER_DISPLAY_TYPE.NOW.value"
        class="weather-simple"
        :class="{ nowNoLocation: isNowNoLocationType }"
      >
        <div class="now-text" :class="{ noLocation: !showLocationText }">
          <div v-if="showLocationText" class="now-city" :style="titleStyle">
            {{ language === WEATHER_LANGUAGES.ENGLISH.value ? 'Now' : 'Nu' }}
          </div>

          <div
            v-show="currentTemp || currentTemp === 0"
            class="now-temperature"
            :style="nowTempStyle"
          >
            {{ currentTemp }}<sup>&deg;</sup>
          </div>

          <div v-if="showLocationText" class="now-city" :style="titleStyle">
            {{ cityName }}
          </div>
        </div>

        <div v-if="isNowNoLocationType" :style="nowSpaceStyle"></div>

        <div class="now-icon" :style="nowIconStyle">
          <WeatherIcons :iconId="weatherData[0].WeatherIcon" :color="app.weather?.iconColor" />
        </div>
      </div>

      <div v-else class="weather-multiple" :class="{ noLocation: !showLocationText }">
        <div v-if="showLocationText" class="multiple-title" :style="titleStyle">
          {{ multipleTitle }}
        </div>

        <div class="multiple-items" :style="multipleItemsContainerStyle">
          <div
            class="item-container"
            v-for="(item, index) of weatherItems"
            :key="index"
            :style="itemStyle"
          >
            <div class="multiple-center">
              <div class="multiple-temperature">
                <div class="temperature" :style="multipleNumbersStyle">
                  {{ item.temperature }}
                </div>

                <div class="lower-temperature" :style="lowerTempStyle">
                  {{ item.minTemperature }}
                </div>
              </div>

              <div class="multiple-icon" :style="multipleIconStyle">
                <WeatherIcons
                  :iconId="item.icon"
                  :color="app.weather ? app.weather.iconColor : defaultValues.iconColor"
                />
              </div>

              <div class="multiple-time" :style="multipleNumbersStyle">
                {{ app.weather.type === WEATHER_DISPLAY_TYPE.HOURS.value ? item.hour : item.day }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="showLocationText" class="now-city" :style="titleStyle">
          {{ cityName }}
        </div>
      </div>
    </div>

    <FloatingOfflineIndicator v-if="!isAppOnline" />
  </div>
</template>

<script>
  import { debounce } from 'lodash';
  import { computed, ref, toRef } from 'vue';
  import { useResizeObserver } from '@vueuse/core';
  import moment from 'moment-timezone';
  import localforage from 'localforage';
  import { hex8ToRgba, isHex8 } from '@/helpers/utils';

  import WeatherIcons from '@/components/apps/weather/WeatherIcons.vue';
  import FloatingOfflineIndicator from '@/components/common/FloatingOfflineIndicator.vue';
  import OfflineMessage from '@/components/common/OfflineMessage.vue';

  import { apiWeatherCurrentConditions } from '@/api/weather';

  import {
    weatherIcons,
    weatherDefaultOptions,
    WEATHER_APP_SCALES,
    WEATHER_DAY_FORMAT,
    WEATHER_DISPLAY_TYPE,
    WEATHER_HOURS_FORMAT,
    WEATHER_TEMPERATURE,
    WEATHER_LANGUAGES,
  } from '@/constants/weatherApp';
  import { cacheData } from '@/helpers/caching';

  export default {
    name: 'WeatherView',

    emits: ['saveData'],

    components: {
      WeatherIcons,
      FloatingOfflineIndicator,
      OfflineMessage,
    },

    props: {
      app: {
        type: Object,
      },
    },

    data() {
      return {
        loading: false,
        error: false,
        refreshDataTimer: null,
        offlineCheckTimer: null,
        WEATHER_DISPLAY_TYPE,
        WEATHER_LANGUAGES,
        defaultValues: weatherDefaultOptions,
        showOfflineMessage: false,
      };
    },

    mounted() {
      this.initWeatherView();
      this.refreshDataTimer = setInterval(this.initWeatherView, 10 * 1000);

      if (!this.isAppOnline && !this.offlineCheckTimer) {
        this.startOfflineCheck();
      }
    },

    beforeDestroy() {
      this.clearOfflineCheckTimer();
      this.clearRefreshDataTimer();
    },

    watch: {
      isAppOnline(newValue) {
        if (!newValue) {
          this.startOfflineCheck();
          return;
        }

        if (this.offlineCheckTimer) {
          this.initWeatherView();
          this.clearOfflineCheckTimer();
        }
      },
    },

    computed: {
      background() {
        const color = this.app.weather
          ? this.app.weather.backgroundColor
          : this.app.background.color;

        return isHex8(color) ? hex8ToRgba(color) : color;
      },

      containerStyle() {
        const { width, height } = this.getContainerSize();

        const paddingBottom = height * this.currentScales.padding.bottom;
        const paddingLeft = width * this.currentScales.padding.left;
        const paddingRight = width * this.currentScales.padding.right;
        const paddingTop = height * this.currentScales.padding.top;

        return {
          color: this.app.weather ? this.app.weather.fontColor : '',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: `${paddingBottom}px`,
          paddingLeft: `${paddingLeft}px`,
          paddingRight: `${paddingRight}px`,
          paddingTop: `${paddingTop}px`,
          fontFamily: this.app.weather ? this.app.weather.textFont : 'Poppins',
          height: height ? `${height}px` : '100%',
          width: width ? `${width}px` : '100%',
        };
      },

      multipleTitle() {
        const isHours = this.app.weather
          ? this.app.weather.type
          : '' === WEATHER_DISPLAY_TYPE.HOURS.value;

        if (this.app.weather.language === WEATHER_LANGUAGES.SWEDISH.value) {
          return isHours ? 'Kommande timmar' : 'Kommande vecka';
        }

        return isHours ? 'The next hours' : 'The next days';
      },

      nowTempStyle() {
        const { height } = this.getContainerSize();

        return {
          fontSize: `${height * this.currentLocationScale.now.numbers.fontSize}px`,
          lineHeight: `${height * this.currentLocationScale.now.numbers.lineHeight}px`,
        };
      },

      nowIconStyle() {
        const adjustedIconWidth = this.getNowIconDimensions();

        return {
          width: `${adjustedIconWidth}px`,
        };
      },

      nowSpaceStyle() {
        if (!this.isNowNoLocationType) return {};

        const { width: containerWidth, height: containerHeight } = this.getContainerSize() || 0;

        const iconWidth = this.getNowIconDimensions();
        const textWidth = containerHeight * 1.3 * this.currentLocationScale.now.numbers.fontSize;
        const paddingLeft = containerWidth * this.currentScales.padding.left;
        const paddingRight = containerWidth * this.currentScales.padding.right;

        const spaceTotalWidth =
          containerWidth - (paddingLeft + paddingRight + iconWidth + textWidth);

        return {
          width: `${spaceTotalWidth * (this.app.weather.widthSeparationPercentage / 100 || 1)}px`,
        };
      },

      titleStyle() {
        const { height } = this.getContainerSize();

        return {
          fontSize: `${height * this.currentLocationScale.now.title.fontSize}px`,
          lineHeight: `${height * this.currentLocationScale.now.title.lineHeight}px`,
          color: this.app.weather ? this.app.weather.fontColor : '',
        };
      },

      multipleIconStyle() {
        const { width = 0 } = this.getContainerSize();

        return {
          width: `${width * this.currentLocationScale.multiple.icon}px`,
        };
      },

      itemStyle() {
        const { width = 0 } = this.getContainerSize();

        return {
          minWidth: `${width * this.currentLocationScale.multiple.itemWidth}px`,
        };
      },

      multipleItemsContainerStyle() {
        const { height } = this.getContainerSize();

        if (!this.app.weather) return {};

        return {
          height: `${
            height * this.currentLocationScale.multiple.bodyHeight[this.app.weather.type]
          }px`,
        };
      },

      multipleNumbersStyle() {
        const { height } = this.getContainerSize();

        return {
          fontSize: `${height * this.currentLocationScale.multiple.numbers.fontSize}px`,
          lineHeight: `${
            height *
            this.currentLocationScale.multiple.numbers.lineHeight *
            (this.is24Format ? 1 : 0.75)
          }px`,
          color: this.app.weather ? this.app.weather.fontColor : '#999999',
        };
      },

      lowerTempStyle() {
        const { height } = this.getContainerSize();

        return {
          fontSize: `${height * this.currentLocationScale.multiple.lowerTemp.fontSize}px`,
          lineHeight: `${height * this.currentLocationScale.multiple.lowerTemp.lineHeight}px`,
          color: this.app.weather ? this.app.weather.fontColor : '',
        };
      },

      showLocationText() {
        return this.app.weather.showLocationText !== false;
      },

      currentLocationScale() {
        return WEATHER_APP_SCALES[this.showLocationText ? 'location' : 'noLocation'];
      },

      location() {
        return this.app.weather &&
          this.app.weather.location &&
          this.app.weather.location.coordinates
          ? this.app.weather.location.coordinates
          : this.app.location || [];
      },

      cityName() {
        const cityName =
          this.app.weather && this.app.weather.location ? this.app.weather.location.label : null;

        if (!cityName)
          return this.weatherData && this.weatherData[0] ? this.weatherData[0].WeatherText : '';

        return cityName;
      },

      measurementSystem() {
        const temperature = this.app.weather
          ? this.app.weather.temperatureUnit
          : this.app.temp_unit;

        return !this.app.weather || temperature === WEATHER_TEMPERATURE.CELSIUS.value
          ? 'Metric'
          : 'Imperial';
      },

      is24Format() {
        return (
          parseInt(this.app.weather ? this.app.weather.timeFormat : this.app.time_format) === 24
        );
      },

      currentTemp() {
        if (
          this.app.weather &&
          this.app.weather.type &&
          this.app.weather.type !== WEATHER_DISPLAY_TYPE.NOW.value
        )
          return '';

        const temperature =
          this.weatherData[0] && this.weatherData[0].Temperature
            ? this.weatherData[0].Temperature[this.measurementSystem].Value
            : null;

        if (!temperature && temperature !== 0) return '';

        return Math.floor(temperature);
      },

      language() {
        return this.app.weather ? this.app.weather.language : this.app.lang;
      },

      weatherIcon() {
        const iconCode = this.weatherData[0]
          ? this.weatherData[0].WeatherIcon
          : this.weatherItems[0].icon;

        return weatherIcons[iconCode];
      },

      dayFormat() {
        if (this.app.weather && this.app.weather.dayFormat === WEATHER_DAY_FORMAT.SHORT.value) {
          return WEATHER_DAY_FORMAT.SHORT.format;
        } else if (
          this.app.weather &&
          this.app.weather.dayFormat === WEATHER_DAY_FORMAT.DAY_OF_MONTH.value
        ) {
          return WEATHER_DAY_FORMAT.DAY_OF_MONTH.format;
        }

        return WEATHER_DAY_FORMAT.WORD.format;
      },

      isNowNoLocationType() {
        return (
          this.app.weather &&
          this.app.weather.type === WEATHER_DISPLAY_TYPE.NOW.value &&
          !this.showLocationText
        );
      },

      lastFetchTimestamp() {
        return this.$store.state.player.appsData[this.app.wid]
          ? this.$store.state.player.appsData[this.app.wid].data.lastFetchTimestamp
          : null;
      },

      weatherData() {
        return this.$store.state.player.appsData[this.app.wid]
          ? this.$store.state.player.appsData[this.app.wid].data.weatherData
          : null;
      },

      weatherItems() {
        if (!this.app.weather || this.app.weather.type === WEATHER_DISPLAY_TYPE.NOW.value) {
          return [];
        }

        const isDailyForecast = this.app.weather.type === WEATHER_DISPLAY_TYPE.DAYS.value;

        const items = isDailyForecast
          ? this.weatherData.DailyForecasts
          : this.weatherData.slice(0, 7);

        return items.map((weather) => {
          const hourFormat = this.is24Format
            ? WEATHER_HOURS_FORMAT.TWENTY_FOUR.format
            : WEATHER_HOURS_FORMAT.TWELVE.format;
          const temperature = isDailyForecast
            ? `${Math.floor(weather.Temperature.Maximum.Value)}º`
            : `${Math.floor(weather.Temperature.Value)}º`;

          return {
            temperature,
            minTemperature: weather.Temperature.Minimum
              ? `${Math.floor(weather.Temperature.Minimum.Value)}º`
              : '',
            icon: isDailyForecast ? weather.Day.Icon : weather.WeatherIcon,
            hour: isDailyForecast ? '' : moment(weather.DateTime).format(hourFormat),
            day: moment(isDailyForecast ? weather.Date : weather.DateTime).format(this.dayFormat),
          };
        });
      },

      isAppOnline() {
        return this.$store.state.offline.isAppOnline;
      },

      offlineTimestamp() {
        return this.$store.state.offline.offlineTimestamp;
      },
    },

    methods: {
      async initWeatherView() {
        this.error = false;

        try {
          if (this.isAppOnline) {
            if (!this.weatherData || this.checkIfRefreshNeeded()) {
              await this.fetchData();
            }

            return;
          }

          const tooLongOffline = this.checkIfTooLongOffline();

          if (this.weatherData && !tooLongOffline) return;

          const data = await localforage.getItem(`weather-app-${this.app.wid}`);

          if (!data) {
            this.showOfflineMessage = true;
            return;
          }

          if (data && tooLongOffline) {
            this.showOfflineMessage = true;

            localforage.removeItem(`weather-app-${this.app.wid}`);
            return;
          }

          this.$emit('saveData', data);
          this.showOfflineMessage = false;
        } catch (error) {
          console.log('error: ', error);
          this.error = true;
        }
      },

      async fetchData() {
        try {
          this.loading = true;

          const response = await apiWeatherCurrentConditions(
            this.location ? this.location.join(',') : [],
            this.language,
            this.app.weather ? this.app.weather.type : undefined,
            this.measurementSystem === 'Metric',
          );

          let data = response ? response.data : null;

          if (!response) {
            data = await localforage.getItem(`weather-app-${this.app.wid}`);

            if (!data) {
              throw new Error(`Weather cache fallback failed. Name: ${this.cityName}`);
            }
          }

          const weatherData = { weatherData: data, lastFetchTimestamp: Date.now() };

          this.$emit('saveData', weatherData);
          cacheData(`weather-app-${this.app.wid}`, weatherData);

          this.showOfflineMessage = false;
        } catch (error) {
          console.log('Error Fetching weather data', error);
        }

        this.loading = false;
      },

      checkIfTooLongOffline() {
        const oneHourAgo = Date.now() - 60 * 60 * 1000;

        return this.offlineTimestamp <= oneHourAgo;
      },

      checkIfRefreshNeeded() {
        const halfHourAgo = Date.now() - 30 * 60 * 1000;

        return this.lastFetchTimestamp <= halfHourAgo;
      },

      checkFetchData() {
        const tenMinutesAgo = Date.now() - 10 * 60 * 1000;

        if (this.weatherData?.lastFetchTimestamp <= tenMinutesAgo) return;

        this.initWeatherView();
      },

      startOfflineCheck() {
        if (this.offlineCheckTimer) {
          clearInterval(this.offlineCheckTimer);
        }

        this.offlineCheckTimer = setInterval(this.initWeatherView, 2 * 60 * 1000);
      },

      clearOfflineCheckTimer() {
        if (!this.offlineCheckTimer) return;

        clearInterval(this.offlineCheckTimer);
        this.offlineCheckTimer = null;
      },

      clearRefreshDataTimer() {
        if (!this.refreshDataTimer) return;

        clearInterval(this.refreshDataTimer);
        this.refreshDataTimer = null;
      },

      getNowIconDimensions() {
        const { width: containerWidth = 0 } = this.getContainerSize();

        const { width: iconWidth = 100, height: iconHeight = 100 } = this.weatherIcon;
        const widthSize = containerWidth * this.currentLocationScale.now.icon.width;

        return 0.85 * iconWidth > iconHeight ? widthSize : widthSize * 0.85;
      },
    },

    setup(props) {
      const app = toRef(props, 'app');

      function getCurrentScales() {
        const isNowType =
          app.value.weather && app.value.weather.type === WEATHER_DISPLAY_TYPE.NOW.value;
        const scaleType = isNowType ? 'now' : 'multiple';

        return this.currentLocationScale[scaleType];
      }

      const weatherContainer = ref(null);
      const containerHeight = ref(null);
      const containerWidth = ref(null);

      const currentScales = computed(getCurrentScales);

      function checkIfResizeObserverIsAvailable() {
        if (!containerHeight.value || !containerWidth.value) {
          if (weatherContainer.value) {
            const rect = weatherContainer.value.getBoundingClientRect();
            containerHeight.value = rect.height;
            containerWidth.value = rect.width;
          }
        }
      }

      function getContainerSize() {
        checkIfResizeObserverIsAvailable();

        const maxHeight =
          containerHeight.value / containerWidth.value < currentScales.value.container
            ? containerHeight.value
            : containerWidth.value * currentScales.value.container;
        const maxWidth = maxHeight / currentScales.value.container;

        return {
          height: maxHeight,
          width: maxWidth,
        };
      }

      useResizeObserver(
        weatherContainer,
        debounce((entries) => {
          const container = entries[0];
          containerHeight.value = container.contentRect.height;
          containerWidth.value = container.contentRect.width;
        }, 500),
      );

      return {
        containerHeight,
        containerWidth,
        currentScales,

        // methods
        getContainerSize,
        weatherContainer,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .loading,
  .weather-error {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    img {
      width: 40px;
      height: 40px;
    }
  }

  .offline {
    color: #6a6b6a;
  }

  .weather-container {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
    font-family: 'Poppins';
    color: #151515;
    position: relative;

    .now-city {
      color: #6a6b6a;
      text-align: left;
      white-space: normal;
    }

    .multiple-title {
      text-align: left;
    }

    .weather-simple {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      width: 100%;

      .now-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        line-height: normal;
        height: 100%;

        &.noLocation {
          justify-content: center;
        }
      }

      .now-icon {
        display: flex;
        align-items: center;
        justify-content: right;
      }

      &.nowNoLocation {
        justify-content: center;
      }
    }

    .weather-multiple {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 100%;

      &.noLocation {
        justify-content: center;
      }

      .multiple-items {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .item-container {
        align-items: center;
        height: 100%;

        .multiple-temperature {
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: center;
          font-weight: bold;
        }

        .multiple-center {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          height: 100%;
        }

        .multiple-time {
          font-weight: bold;
          text-align: center;
        }
      }
    }
  }
</style>
