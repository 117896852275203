<template>
  <div class="widget-item" :id="widget.object.wid" :style="style">
    <TrafiklabViewer
      :viewerStyles="widget.object.viewerStyles"
      :timetable="widget.object.timetable"
      :appId="widget.object.wid"
      :app="widget.object"
      :name="widget.object.name"
      alt="Trafiklab app"
      @saveData="saveAppData"
    />
  </div>
</template>

<script>
  import TrafiklabViewer from '@/components/common/timetable/TrafiklabViewer.vue';

  import templateWidget from '@/components/widgets/templates/mixins/templateWidget';
  import { simpleTypeMixin } from '@/helpers';
  import { SET_APP_DATA } from '@/store/actions/player';

  export default {
    name: 'TrafiklabWidget',

    components: { TrafiklabViewer },

    mixins: [simpleTypeMixin, templateWidget],

    props: {
      widget: {
        type: Object,
        required: true,
      },
    },

    computed: {
      style() {
        return {
          ...this.defaultStyling(this.widget),
          overflow: 'hidden',
        };
      },
    },

    methods: {
      saveAppData(data = null) {
        this.$store.commit(SET_APP_DATA, {
          appId: this.widget.object.wid,
          appData: { appType: this.widget.object.item_type, data },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .widget-item {
    position: absolute;
    overflow: hidden;
  }
</style>
