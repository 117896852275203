<template>
  <div class="timetable" :style="{ backgroundColor: backgroundColor }">
    <OfflineMessage v-if="showOfflineMessage" message="No stops data" />

    <template v-else>
      <h5 class="title" :style="getFontStyle(viewerStyles.title)">
        {{ getStopName() }}
      </h5>
      <div class="my-carousel">
        <PreLoader v-if="loading" class="styled-loader" />
        <ErrorState
          v-else-if="isError"
          title="Something went wrong"
          content="Sorry, we are unable to connect to Trafiklab at the moment."
        />
        <table v-else-if="hasData" class="my-table">
          <template>
            <tbody
              class="my-carousel-item"
              v-for="(data, i) in stopGroups[currentSlideIndex]"
              :key="i"
            >
              <tr :style="{ ...getRowStyle(i) }">
                <td>
                  <span class="line-box" :style="getLineBoxStyle(viewerStyles?.line)">
                    {{ data.lineNumber }}
                  </span>
                </td>
                <td>{{ getLocalizedDirectionText() }} {{ data.direction }}</td>
                <td class="datetime-info">
                  {{ formatDateTime(data.departureTime, 'HH:mm') }}
                </td>
              </tr>
            </tbody>
          </template>
        </table>

        <EmptyState
          v-else
          :content="
            hasData && filteredStopData.length === 0
              ? 'Sorry, no data was returned from Trafiklab.'
              : 'Sorry, no stops available.'
          "
        />
      </div>
    </template>

    <FloatingOfflineIndicator v-if="!isAppOnline" />
  </div>
</template>

<script>
  import localforage from 'localforage';
  import moment from 'moment';

  import PreLoader from '@/components/common/PreLoader.vue';
  import { DEFAULT_TRAFIKLAB_VIEWER_STYLE } from '@/constants/trafiklabWidget';
  import ErrorState from '@/components/common/ui/errorState/ErrorState.vue';
  import EmptyState from '@/components/common/ui/emptyState/EmptyState.vue';
  import FloatingOfflineIndicator from '@/components/common/FloatingOfflineIndicator.vue';
  import OfflineMessage from '@/components/common/OfflineMessage.vue';

  import { getDepartures } from '@/api/transport';

  import { getRBGAColor, hex8ToRgba, isHex8 } from '@/helpers/utils';
  import { TL_LANGUAGES, TL_TRAFFIC_TYPES } from '@/config/constants';
  import { cacheData } from '@/helpers/caching';
  import { SET_APP_DATA } from '@/store/actions/player';

  export default {
    name: 'TrafiklabViewer',

    emits: ['saveData'],

    components: {
      EmptyState,
      ErrorState,
      PreLoader,
      FloatingOfflineIndicator,
      OfflineMessage,
    },

    props: {
      app: {
        type: Object,
        default: () => null,
      },
      name: {
        type: String,
        default: '',
      },
      viewerStyles: {
        type: Object,
        default: () => ({}),
      },
      timetable: {
        type: Array,
        default: () => [],
      },
      carouselInterval: {
        type: Number,
        default: 15000,
      },
      pageSize: {
        type: Number,
        default: 6,
      },
    },

    data() {
      return {
        loading: false,
        isError: false,
        fetchDataTimer: null,
        slideInterval: null,
        currentSlideIndex: 0,
        stopName: null,
        showOfflineMessage: false,
        offlineCheckTimer: null,
      };
    },

    mounted() {
      this.initTrafiklabView();
      this.fetchDataTimer = setInterval(this.initTrafiklabView, 10 * 1000);
      this.startSlideInterval();

      if (!this.isAppOnline && !this.offlineCheckTimer) {
        this.startOfflineCheck();
      }
    },

    beforeDestroy() {
      this.clearFetchDataTimer();
      this.clearOfflineCheckTimer();
      this.clearSlideInterval();
    },

    watch: {
      isAppOnline(newValue) {
        if (!newValue) {
          this.startOfflineCheck();
          return;
        }

        if (this.offlineCheckTimer) {
          this.initTrafiklabView();
          this.clearOfflineCheckTimer();
        }
      },
    },

    computed: {
      backgroundColor() {
        var opacity = 0;

        // handle migration from old hex6 values
        if (!isHex8(this.viewerStyles.background.color)) {
          opacity = 1 - this.viewerStyles.background.transparency / 100;
          return getRBGAColor(this.viewerStyles.background.color, opacity);
        } else {
          return hex8ToRgba(this.viewerStyles.background.color);
        }
      },

      hasData() {
        return this.stopData && this.filteredStopData.length > 0;
      },

      filteredStopData() {
        const now = moment();
        const stops = this.stopData || [];

        return stops.filter((stop) => {
          const departureTime = moment(stop.departureTime);
          const differenceInMinutes = departureTime.diff(now, 'minutes');
          const hideTime = parseInt(this.timetable[0]?.hideTime || 0);

          return differenceInMinutes >= hideTime;
        });
      },

      stopGroups() {
        const groupCount = Math.ceil(this.filteredStopData.length / this.pageSize);
        const data = [];

        for (let i = 0; i < groupCount; i++) {
          const group = [];

          for (let j = 0; j < this.pageSize; j++) {
            const index = i * this.pageSize + j;

            if (index >= this.filteredStopData.length) {
              break;
            }
            group.push(this.filteredStopData[index]);
          }
          data.push(group);
        }

        return data;
      },

      stopData() {
        return this.$store.state.player.appsData[this.app.wid]
          ? this.$store.state.player.appsData[this.app.wid].data.stops
          : null;
      },

      isAppOnline() {
        return this.$store.state.offline.isAppOnline;
      },

      offlineTimestamp() {
        return this.$store.state.player.appsData[this.app.wid]
          ? this.$store.state.player.appsData[this.app.wid].data.lastFetchTimestamp
          : null;
      },
    },

    methods: {
      getNextSlideIndex() {
        this.currentSlideIndex =
          this.currentSlideIndex + 1 < this.stopGroups.length ? this.currentSlideIndex + 1 : 0;
      },

      startSlideInterval() {
        if (this.slideInterval) {
          clearInterval(this.slideInterval);
        }
        this.slideInterval = setInterval(this.getNextSlideIndex, this.carouselInterval);
      },

      clearFetchDataTimer() {
        if (!this.fetchDataTimer) return;

        clearInterval(this.fetchDataTimer);
        this.fetchDataTimer = null;
      },

      clearSlideInterval() {
        if (!this.slideInterval) return;

        clearInterval(this.slideInterval);
        this.slideInterval = null;
      },

      getStopName() {
        return this.stopName != null ? this.stopName : this.timetable[0].stop.name;
      },

      getLanguage() {
        return this.timetable[0].language;
      },

      getLocalizedDirectionText() {
        const language = this.getLanguage();

        if (language === TL_LANGUAGES.Swedish) {
          return 'mot';
        }

        return '';
      },

      getLocalizedNowText() {
        const language = this.getLanguage();

        if (language === TL_LANGUAGES.Swedish) {
          return 'Nu';
        } else {
          return 'Now';
        }
      },

      getFontStyle(style) {
        const fontSize = (style && `${style.fontSize}px`) || '12px';

        const formattedStyles = {
          fontStyle: 'normal',
          fontSize: fontSize,
          fontFamily: style?.fontType || 'Arial',
          color: hex8ToRgba(style?.fontColor || '#000000FF'),
        };

        if (['Italic', 'Bold Italic'].includes(style.fontStyle)) {
          formattedStyles['fontStyle'] = 'italic';
        }

        if (['Bold', 'Bold Italic'].includes(style.fontStyle)) {
          formattedStyles['fontWeight'] = 'bold';
        }

        return formattedStyles;
      },

      getLineBoxStyle(style) {
        return {
          color: hex8ToRgba(style?.fontColor || DEFAULT_TRAFIKLAB_VIEWER_STYLE.line.fontColor),
          backgroundColor: hex8ToRgba(
            style?.backgroundColor || DEFAULT_TRAFIKLAB_VIEWER_STYLE.line.backgroundColor,
          ),
        };
      },

      getRowStyle(index) {
        if ((index + 1) % 2 === 0) {
          return Object.assign({}, this.getFontStyle(this.viewerStyles.departures), {
            backgroundColor: hex8ToRgba(this.viewerStyles.evenDeparturesBackground),
          });
        }

        return Object.assign({}, this.getFontStyle(this.viewerStyles.departures), {
          backgroundColor: hex8ToRgba(this.viewerStyles.oddDeparturesBackground),
        });
      },

      async initTrafiklabView() {
        this.error = false;
        try {
          if (this.isAppOnline) {
            if (!this.stopData || this.checkIfRefreshNeeded()) {
              await this.loadData();
              this.currentSlideIndex = 0;
              this.startSlideInterval();
            }

            return;
          }

          if (this.stopData && this.filteredStopData.length > 0) return;

          const data = await localforage.getItem(`trafiklab-app-${this.app.wid}`);

          if (!data) {
            this.showOfflineMessage = true;
            return;
          }

          this.$emit('saveData', data);
          this.showOfflineMessage = false;
        } catch (error) {
          console.log('error: ', error);
          this.isError = true;
        }
      },

      checkIfRefreshNeeded() {
        const fiveMinutesAgo = Date.now() - 5 * 60 * 1000;

        return this.stopData && this.offlineTimestamp <= fiveMinutesAgo;
      },

      startOfflineCheck() {
        if (this.offlineCheckTimer) {
          clearInterval(this.offlineCheckTimer);
        }

        this.offlineCheckTimer = setInterval(this.initTrafiklabView, 2 * 60 * 1000);
      },

      clearOfflineCheckTimer() {
        if (!this.offlineCheckTimer) return;

        clearInterval(this.offlineCheckTimer);
        this.offlineCheckTimer = null;
      },

      async loadData() {
        this.isError = false;
        const stopData = [];
        this.loading = true;

        try {
          const timetable = this.timetable[0];
          const stopId = timetable && timetable.stop ? timetable.stop.id : null;

          if (!stopId) {
            return;
          }

          const lang = timetable.language;
          const transports = TL_TRAFFIC_TYPES.filter(
            (type) => timetable && timetable.trafficTypes[type] === true,
          );

          let departures = await getDepartures(stopId, transports, lang, timetable.hideTime);

          if (!departures) {
            departures = await localforage.getItem(`trafiklab-app-departures-${this.app.wid}`);

            if (!departures) {
              throw new Error(`Trafiklab cache fallback failed. Name: ${this.app.name}`);
            }
          }

          if (departures.length > 0) {
            this.stopName = departures[0].stopName;
          }

          departures.forEach((departure) => {
            if (!timetable.show && !moment(departure.scheduledTime).isAfter(moment())) {
              return;
            }

            stopData.push({
              lineNumber: departure.lineNumber,
              direction: departure.direction,
              departureTime: departure.scheduledTime,
            });
          });

          const dataWithTimestamp = { stops: stopData, lastFetchTimestamp: Date.now() };
          cacheData(`trafiklab-app-${this.app.wid}`, dataWithTimestamp);
          cacheData(`trafiklab-app-departures-${this.app.wid}`, departures);

          this.$emit('saveData', dataWithTimestamp);
          this.showOfflineMessage = false;
        } catch (error) {
          console.error('Failed to get data from Trafiklab');
          this.isError = true;
        }

        this.loading = false;
      },

      formatDateTime(dateTime, format = 'Y-MM-DD') {
        const now = moment();
        const departureTime = moment(dateTime);

        const diffInMinutes = departureTime.diff(now, 'minutes');

        if (
          diffInMinutes >= 0 &&
          diffInMinutes <= 20 &&
          now.date() === departureTime.date() &&
          now.hour() === departureTime.hour()
        ) {
          if (diffInMinutes === 0) {
            return this.getLocalizedNowText();
          }

          return `${diffInMinutes} min`;
        } else {
          return departureTime.format(format);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .styled-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    width: 100%;
    height: 100%;
  }

  .my-carousel {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .my-carousel-item {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #dee2e6;

    tr {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;

      td:first-child {
        padding: 8px 12px;
        vertical-align: middle;
      }

      td:nth-child(2) {
        padding: 0;
        vertical-align: middle;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }

      td:last-of-type {
        padding: 8px 12px;
        vertical-align: middle;
        margin-top: 0;
        margin-left: auto;
        min-width: 92px;
        white-space: nowrap;
      }
    }
  }

  .line-box {
    display: flex;
    justify-content: center;
    width: fit-content;
    min-width: 32px;
    padding: 8px;
    border-radius: 8px;
    vertical-align: middle;
    text-align: center;
    font-weight: bold;
    box-sizing: content-box;
  }

  .datetime-info {
    vertical-align: middle;
    text-align: center;
  }

  .my-table {
    border-collapse: collapse;
    width: 100%;
  }

  .timetable {
    height: 100%;
    width: 100%;

    .title {
      padding: 16px;
      margin: 0;
    }
  }
</style>
