<template>
  <div v-if="showOfflineMessage" class="offline">
    <i class="material-icons-outlined align-middle">wifi_off</i>
    <span class="offline-text">No weather data</span>
  </div>

  <div v-else-if="loading" class="loading">
    <img src="@/assets/loader.svg" alt="loading" />
  </div>

  <div v-else-if="error" class="weather-error">Unable to fetch weather data</div>

  <div v-else class="weather-container" :style="mainStyle">
    <div class="weather-app">
      <div
        class="weather-left"
        v-if="!app.visibility || app.visibility?.time || app.visibility?.date"
      >
        <div v-if="!app.visibility || app.visibility?.date" class="weather-date" :style="dateStyle">
          {{ currentDay }}
        </div>

        <div v-if="!app.visibility || app.visibility?.time" class="weather-time" :style="timeStyle">
          {{ currentTime }}<sup class="time-period" v-if="!is24Format">{{ clockTime }}</sup>
        </div>

        <div v-if="!app.visibility || app.visibility?.date" class="weather-date" :style="dateStyle">
          {{ currentDate }}
        </div>
      </div>

      <div
        v-if="!app.visibility || app.visibility?.weather"
        class="weather-right"
        :style="rightStyle"
      >
        <img class="weather-image" :src="currentWeatherImage" alt="icon" :style="imageStyle" />
        <div v-if="currentTemp" class="weather-temperature" :style="degreesStyle">
          {{ currentTemp }}<sup>&deg;</sup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment-timezone';
  import config from '@/config';
  import localforage from 'localforage';

  const timezone = config.timezone;

  import { apiWeatherCurrentConditions } from '@/api/weather';
  import { getRBGAColor } from '@/helpers/utils';
  import { cacheData } from '@/helpers/caching';

  export default {
    name: 'WeatherViewOld',

    emits: ['saveData'],

    props: {
      app: {
        type: Object,
      },
    },

    data() {
      return {
        loading: true,
        error: false,
        appData: null,
        showOfflineMessage: false,
        defaultStyle: {
          logoSize: 60,
          lineWidth: 1,
          lineColor: '#F0F0F0',
          alignment: 'center',
          textSizeLeft1: 14,
          textSizeLeft2: 30,
          textSizeRight: 32,
        },
        timer: null,
        fetcherTimer: null,
        clockObj: null,
        offlineCheckTimer: null,
      };
    },

    watch: {
      isAppOnline(newValue) {
        if (!newValue) {
          this.startOfflineCheck();
          return;
        }

        if (this.offlineCheckTimer) {
          this.initWeatherView();
        }
      },
    },

    mounted() {
      this.initWeatherView();
      this.updateClock();

      if (this.timer) {
        clearInterval(this.timer);
      }

      this.timer = setInterval(this.clockTick, 1000);
    },

    beforeDestroy() {
      this.clearOfflineCheckTimer();

      if (this.timer) clearInterval(this.timer);

      if (this.fetcherTimer) {
        clearInterval(this.fetcherTimer);
      }
    },

    computed: {
      background() {
        return this.app && this.app && this.app.background
          ? this.app.background
          : this.defaultStyle;
      },

      measurementSystem() {
        if (!this.app) return 'Imperial';
        if (this.app.temp_unit === 'F') {
          return 'Imperial';
        }
        return 'Metric';
      },

      currentDay() {
        return moment(this.clockObj).format('dddd');
      },

      currentTime() {
        const format = this.is24Format ? 'HH:mm' : 'hh:mm';

        return moment(this.clockObj).format(format);
      },

      clockTime() {
        return moment(this.clockObj).format('a');
      },

      is24Format() {
        return this.app && parseInt(this.app.time_format) === 24;
      },

      currentDate() {
        return moment(this.clockObj).format('L');
      },

      currentTemp() {
        if (!this.currentConditions || !this.currentConditions.Temperature) return;

        const temperature = this.currentConditions.Temperature[this.measurementSystem].Value;

        if (!temperature) return '';

        return Math.floor(temperature);
      },

      currentFeelsLikeTemp() {
        if (!this.currentConditions || !this.currentConditions.RealFeelTemperature) return;

        return this.currentConditions.RealFeelTemperature[this.measurementSystem].Value;
      },

      currentWeatherText() {
        if (!this.currentConditions) return;

        return this.currentConditions.WeatherText;
      },

      currentWeatherImage() {
        const imageCode =
          this.currentConditions && this.currentConditions.WeatherIcon
            ? this.currentConditions.WeatherIcon
            : 1;

        return require(`@/assets/icon/weather/icon-${imageCode}.svg`);
      },

      dateStyle() {
        return {
          fontSize: `${this.background.textSizeLeft1 || 14}px`,
          lineHeight: `${this.background.textSizeLeft1 * 1.5 || 21}px`,
        };
      },

      timeStyle() {
        return {
          fontSize: `${this.background.textSizeLeft2 || 30}px`,
          lineHeight: `${this.background.textSizeLeft2 * 1.5 || 45}px`,
        };
      },

      mainStyle() {
        const opacity = this.background.transparency / 100;

        const backgroundColor = getRBGAColor(this.background.color, opacity);

        return {
          backgroundColor,
          justifyContent: this.background.alignment || 'center',
        };
      },

      rightStyle() {
        let style = {};

        if (!this.app.visibility || this.app.visibility.time || this.app.visibility.date) {
          style = {
            borderLeft: `${this.background.lineWidth || 1}px solid ${
              this.background.lineColor || '#F0F0F0'
            }`,
          };
        }

        return style;
      },

      degreesStyle() {
        return {
          fontSize: `${this.background.textSizeRight || 32}px`,
        };
      },

      imageStyle() {
        return {
          width: `${this.background.logoSize || 60}px`,
          height: `${this.background.logoSize || 60}px`,
        };
      },

      currentConditions() {
        return this.$store.state.player.appsData[this.app.wid]
          ? this.$store.state.player.appsData[this.app.wid].data
          : null;
      },

      isAppOnline() {
        return this.$store.state.offline.isAppOnline;
      },

      offlineTimestamp() {
        return this.$store.state.offline.offlineTimestamp;
      },
    },

    methods: {
      async initWeatherView() {
        try {
          if (this.isAppOnline) {
            if (!this.fetcherTimer) {
              this.fetcherTimer = setInterval(this.checkFetchData, 10 * 60 * 1000);
            }

            if (!this.currentConditions || this.checkIfRefreshNeeded()) {
              await this.fetchData();
            }

            return;
          }

          const data = await localforage.getItem(`weather-app-${this.app.wid}`);

          if (!data) {
            this.showOfflineMessage = true;
            return;
          }

          if (data && this.checkIfTooLongOffline()) {
            this.showOfflineMessage = true;
            localforage.removeItem(`weather-app-${this.app.wid}`);
            return;
          }

          this.$emit('saveData', data);
          this.showOfflineMessage = false;
        } catch (error) {
          console.log('error: ', error);
          this.error = true;
        }
      },

      async fetchData() {
        this.loading = true;

        try {
          const appConfig = this.app;

          const response = await apiWeatherCurrentConditions(
            appConfig.location.join(','),
            appConfig.lang,
          );

          let data = response ? response.data[0] : null;

          if (!response) {
            data = await localforage.getItem(`weather-app-${this.app.wid}`);

            if (!data) {
              throw new Error(`Weather cache fallback failed. App's name: ${this.app.name}`);
            }
          }

          const weatherData = { ...data, lastFetchTimestamp: Date.now() };
          this.$emit('saveData', weatherData);
          cacheData(`weather-app-${this.app.wid}`, weatherData);

          this.showOfflineMessage = false;
        } catch (error) {
          console.log('Error Fetching weather data', error);
        }

        this.loading = false;
      },

      checkIfTooLongOffline() {
        const oneHourAgo = Date.now() - 60 * 60 * 1000;
        return this.offlineTimestamp <= oneHourAgo;
      },

      checkIfRefreshNeeded() {
        const halfHourAgo = Date.now() - 30 * 60 * 1000;

        return this.currentConditions?.lastFetchTimestamp <= halfHourAgo;
      },

      checkFetchData() {
        const tenMinutesAgo = Date.now() - 10 * 60 * 1000;
        if (this.currentConditions?.lastFetchTimestamp <= tenMinutesAgo) return;
        this.initWeatherView();
      },

      startOfflineCheck() {
        if (this.offlineCheckTimer) {
          clearInterval(this.offlineCheckTimer);
        }
        this.offlineCheckTimer = setInterval(this.initWeatherView, 2 * 60 * 1000);
      },

      clearOfflineCheckTimer() {
        if (!this.offlineCheckTimer) return;

        clearInterval(this.offlineCheckTimer);
        this.offlineCheckTimer = null;
      },

      weatherImageFromCode(code) {
        const fileName = String(code).padStart(2, '0');
        return `https://developer.accuweather.com/sites/default/files/${fileName}-s.png`;
      },

      isDayTime(timezone, sunrise, sunset) {
        const now = moment().tz(timezone);
        const sunriseMoment = moment(sunrise);
        const sunsetMoment = moment(sunset);
        return now.isBetween(sunriseMoment, sunsetMoment);
      },

      updateClock() {
        this.clockObj = moment();
        this.clockObj.tz(timezone);
      },

      clockTick() {
        this.updateClock();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .loading,
  .weather-error {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    img {
      width: 40px;
      height: 40px;
    }
  }

  .weather-container {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    padding: 24px;
    font-family: 'Poppins';
    color: #151515;

    .weather-app {
      display: flex;
    }

    .weather-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: space-between;
      padding: 0 16px 0 4px;
      text-align: center;

      .weather-date {
        font-weight: 600;
        color: #e3003a;
        text-transform: uppercase;
        letter-spacing: 2px;
        word-spacing: 2px;
        font-size: 14px;
      }

      .weather-time {
        font-weight: 275;
        font-size: 2.5em;
      }
    }

    .weather-right {
      display: flex;
      // gap: max(20px, 5%);
      align-items: center;
      padding-right: min(16px, 5%);
      padding-left: min(16px, 5%);
      margin: 10px 0;

      .weather-image {
        width: min(50%, 100px);
        margin-right: max(20px, 5%);
      }

      .weather-temperature {
        font-weight: 600;
        font-size: 2.63em;
      }
    }
  }

  .offline {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #666;

    i {
      font-size: 24px;
      margin-bottom: 8px;
    }

    .offline-text {
      font-size: 14px;
    }
  }
</style>
