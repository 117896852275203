import { UNIQUE_SCREEN_CODE, SCREEN_ID_ENUM } from '@/constants';
import { ScreenEvent } from '@/types/api/login';
import http from '@/utils/http';
import config from '@/config';
import { AxiosResponse } from 'axios';

export async function getScreenEvents(): Promise<ScreenEvent> {
  const screenCode = localStorage.getItem(UNIQUE_SCREEN_CODE);
  const response = await http.get<ScreenEvent>(
    `${config.apiUrl}userapi/screen-event?uniqueScreenCode=${screenCode}`,
    {
      'axios-retry': {
        retries: 0,
      },
    },
  );
  return response.data;
}

export async function saveScreenEvents(
  patchRequest: Record<string, string | boolean>,
): Promise<AxiosResponse> {
  const screenCode = localStorage.getItem(UNIQUE_SCREEN_CODE);
  const response = await http.patch<AxiosResponse>(`${config.apiUrl}userapi/screen-event`, {
    ...patchRequest,
    uniqueScreenCode: screenCode,
  });
  return response.data;
}

export async function saveDeviceInfo(deviceInfo: Record<string, any>) {
  const screenCode = localStorage.getItem(UNIQUE_SCREEN_CODE);
  //use Object.assign to merge two objects instead of spread operator
  const response = await http.post<AxiosResponse>(
    `${config.apiUrl}userapi/screen-info`,
    Object.assign(
      {
        playerVersion: config.version,
      },
      deviceInfo,
      {
        uniqueScreenCode: screenCode,
      },
    ),
  );
}
